import "../styles/index.scss";
import axios from "axios";

if (process.env.NODE_ENV === "development") {
    require("../index.html");
}

(() => {
    document.addEventListener("DOMContentLoaded", () => {
        const getAll = (selector) => {
            return Array.prototype.slice.call(document.querySelectorAll(selector), 0);
        };

        new Swiper(".swiper-container", {
            loop: true,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            autoplay: {
                delay: 5000,
            },
        });

        window.gotoStep = (step) => {
            const $steps = getAll(".step");

            if (step === 4) {
                const form = document.getElementById("enquiryForm-Ready");

                if (form.plan.value == "") {
                    swal("Please select a plan.");
                    return;
                }

                const planValue = form.plan.value;
                const $typeDropdown = document.getElementById("planType");
                const $speedDropdown = document.getElementById("planSpeed");
                let planSplitted = planValue.split("_");

                if (planSplitted.length && planSplitted.length === 3) {
                    $typeDropdown.value = planSplitted[0];
                    $speedDropdown.value = planSplitted[2];
                    $typeDropdown.dispatchEvent(new Event("change"));
                }
            }

            $steps.forEach(($el) => $el.classList.remove("is-active"));
            $steps[step - 1].classList.add("is-active");
        };

        const $priceCardsButtons = getAll(".price-cards-section .price-card-wrapper");

        $priceCardsButtons.forEach(($el) => {
            $el.addEventListener("click", () => {
                $priceCardsButtons.forEach(($el) => $el.classList.remove("is-active"));
                $el.classList.add("is-active");
            });
        });

        const $navbarBurgers = getAll(".navbar-burger");
        if ($navbarBurgers.length > 0) {
            $navbarBurgers.forEach((el) => {
                el.addEventListener("click", () => {
                    const target = el.dataset.target;
                    const $target = document.getElementById(target);
                    const backdrop = document.getElementById("nav-backdrop");
                    el.classList.toggle("is-active");
                    $target.classList.toggle("is-active");
                    backdrop.classList.toggle("is-active");
                });
            });
        }
    });

    function isValidJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }

        return true;
    }

    window.addEventListener(
        "message",
        ({ data }) => {
            if (typeof data === "string" && isValidJson(data)) {
                const { coords, coverage, address } = JSON.parse(data);
                window.coords = coords;
                window.coverage = coverage;
                window.address = address;

                const $addressField = getAll(".address-field");
                const $latField = getAll(".lat-field");
                const $lonField = getAll(".lon-field");

                $addressField.forEach(($el) => ($el.innerText = address));
                $latField.forEach(($el) => ($el.innerText = window.coords.lat));
                $lonField.forEach(($el) => ($el.innerText = window.coords.lng));

                document.querySelector("#jumpToStepsForm").click();

                if (coverage === "fibre_ready") {
                    window.gotoStep(2);
                } else if (coverage === "fibre_soon" || coverage === "fibre_planned") {
                    window.gotoStep(9);
                } else {
                    window.gotoStep(7);
                }
            }
        },
        false
    );

    window.submitForm = async (which) => {
        let form;

        if (which === "Ready") {
            form = document.getElementById("enquiryForm-Ready");
        } else if (which === "comingSoon") {
            form = document.getElementById("enquiryForm-comingSoon");
        } else if (which === "noCoverage") {
            form = document.getElementById("enquiryForm-noCoverage");
        }

        const seralizeForm = Object.values(form).reduce((obj, field) => {
            obj[field.name] = field.value;
            return obj;
        }, {});
        let data = {};

        if (window.coverage === "fibre_soon" || window.coverage === "fibre_planned") {
            data.firstName = seralizeForm.comingSoon_firstName;
            data.lastName = seralizeForm.comingSoon_lastName;
            data.email = seralizeForm.comingSoon_email;
            data.contact = seralizeForm.comingSoon_contact;
            data.plan = "N/A";
            data.planSpeed = "N/A";
            data.planType = "N/A";
        } else if (window.coverage === "fibre_ready") {
            data.firstName = seralizeForm.firstName;
            data.lastName = seralizeForm.lastName;
            data.email = seralizeForm.email;
            data.contact = seralizeForm.contact;
            data.plan = seralizeForm.plan;
            data.planSpeed = seralizeForm.planSpeed;
            data.planType = seralizeForm.planType;
        } else {
            data.firstName = seralizeForm.noCoverage_firstName;
            data.lastName = seralizeForm.noCoverage_lastName;
            data.email = seralizeForm.noCoverage_email;
            data.contact = seralizeForm.noCoverage_contact;
            data.plan = "N/A";
            data.planSpeed = "N/A";
            data.planType = "N/A";
        }

        if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(data.email)) {
            setTimeout(function () {
                swal("Please enter a valid email address.");
            }, 100);
            return;
        }

        data.address = window.address;
        data.lat = window.coords.lat;
        data.lng = window.coords.lng;
        data.coverage = window.coverage;

        form.querySelector("button[type=submit].is-primary").classList.add("is-loading");

        const response = await axios
            .post("/public/form-submit.php", {
                data: data,
            })
            .then(function (response) {
                if (window.coverage === "fibre_soon" || window.coverage === "fibre_planned") {
                    gotoStep(8);
                } else if (window.coverage === "fibre_ready") {
                    gotoStep(5);
                } else {
                    gotoStep(8);
                }
                form.querySelector("button[type=submit].is-primary").classList.remove("is-loading");
            })
            .catch(function (error) {
                swal("Error occured, Please try again!");
                gotoStep(1);
                form.querySelector("button[type=submit].is-primary").classList.remove("is-loading");
            });

        return;
    };

    var infoToggle = document.querySelector(".sa-fibre-info-toggle");
    var infoBox = document.querySelector(".sa-fibre-info");
    infoToggle.addEventListener("click", function () {
        infoBox.classList.toggle("is-active");
    });

    // Modals

    var rootEl = document.documentElement;
    var $modals = getAll(".modal");
    var $modalButtons = getAll(".modal-button");
    var $modalCloses = getAll(".modal-background, .modal-close, .modal-card-head .delete, .modal-card-foot .button");

    if ($modalButtons.length > 0) {
        $modalButtons.forEach(function ($el) {
            $el.addEventListener("click", function () {
                var target = $el.dataset.target;
                openModal(target);
            });
        });
    }

    if ($modalCloses.length > 0) {
        $modalCloses.forEach(function ($el) {
            $el.addEventListener("click", function () {
                closeModals();
            });
        });
    }

    function openModal(target) {
        var $target = document.getElementById(target);
        rootEl.classList.add("is-clipped");
        $target.classList.add("is-active");
    }

    function closeModals() {
        rootEl.classList.remove("is-clipped");
        $modals.forEach(function ($el) {
            $el.classList.remove("is-active");
        });
    }

    document.addEventListener("keydown", function (event) {
        var e = event || window.event;
        if (e.keyCode === 27) {
            closeModals();
            closeDropdowns();
        }
    });

    function getAll(selector) {
        var parent = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : document;

        return Array.prototype.slice.call(parent.querySelectorAll(selector), 0);
    }

    document.querySelectorAll("input[name$=contact]").forEach(function (el) {
        el.addEventListener("invalid", function (e) {
            swal("Please enter a valid 10 digit phone number.");
        });
    });

    document.querySelectorAll("input[type=email]").forEach(function (el) {
        el.addEventListener("invalid", function (e) {
            setTimeout(function () {
                swal("Please enter a valid email address.");
            }, 100);
        });
    });

    document.querySelector("select#planType").addEventListener("change", function () {
        var selectedPlanType = this.value;
        var isSelectedPlanSpeedValid = 0;
        var selectedPlanSpeed = document.querySelector("select#planSpeed").value;
        document.querySelectorAll("select#planSpeed option").forEach(function (el) {
            if(el.dataset.plantype != undefined) {
                if (el.dataset.plantype.toUpperCase() == selectedPlanType.toUpperCase()) {
                    el.classList.remove("is-hidden");
                    if(selectedPlanSpeed == el.value) {
                        isSelectedPlanSpeedValid = 1;
                    }
                } else {
                    el.classList.add("is-hidden");
                }
            }
        });
        if(!isSelectedPlanSpeedValid) {
            document.querySelector("select#planSpeed").value = "";
        }
    });
})();
